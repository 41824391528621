import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from '../components/helpers';
import AdjustableBaseHeadUp from '../../src/Icons/AdjustableBaseHeadUp';
import AdjustableBaseHeadDown from '../../src/Icons/AdjustableBaseHeadDown';
import AdjustableBaseFeetUp from '../../src/Icons/AdjustableBaseFeetUp';
import AdjustableBaseFeetDown from '../../src/Icons/AdjustableBaseFeetDown';
import AdjustableBaseZeroGravity from '../../src/Icons/AdjustableBaseZeroGravity';
import AdjustableBaseFlat from '../../src/Icons/AdjustableBaseFlat';
import AdjustableBaseMassage from '../../src/Icons/AdjustableBaseMassage';
import AdjustableBaseLight from '../../src/Icons/AdjustableBaseLight';
import AdjustableBaseLumbarUp from '../../src/Icons/AdjustableBaseLumbarUp';
import AdjustableBaseLumbarDown from '../../src/Icons/AdjustableBaseLumbarDown';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import IconName from '@mfrm/mfcl/Icons/[Icon Name]'
`}</code></pre>
    <Playground __position={0} __code={'<Row>\n  <AdjustableBaseHeadUp width={30} height={30} />\n  <AdjustableBaseHeadDown width={30} height={30} />\n  <AdjustableBaseFeetUp width={30} height={30} />\n  <AdjustableBaseFeetDown width={30} height={30} />\n  <AdjustableBaseZeroGravity width={30} height={30} />\n  <AdjustableBaseFlat width={30} height={30} />\n  <AdjustableBaseMassage width={30} height={30} />\n  <AdjustableBaseLight width={30} height={30} />\n  <AdjustableBaseLumbarUp width={30} height={30} />\n  <AdjustableBaseLumbarDown width={30} height={30} />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      AdjustableBaseHeadUp,
      AdjustableBaseHeadDown,
      AdjustableBaseFeetUp,
      AdjustableBaseFeetDown,
      AdjustableBaseZeroGravity,
      AdjustableBaseFlat,
      AdjustableBaseMassage,
      AdjustableBaseLight,
      AdjustableBaseLumbarUp,
      AdjustableBaseLumbarDown,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <AdjustableBaseHeadUp width={30} height={30} mdxType="AdjustableBaseHeadUp" />
    <AdjustableBaseHeadDown width={30} height={30} mdxType="AdjustableBaseHeadDown" />
    <AdjustableBaseFeetUp width={30} height={30} mdxType="AdjustableBaseFeetUp" />
    <AdjustableBaseFeetDown width={30} height={30} mdxType="AdjustableBaseFeetDown" />
    <AdjustableBaseZeroGravity width={30} height={30} mdxType="AdjustableBaseZeroGravity" />
    <AdjustableBaseFlat width={30} height={30} mdxType="AdjustableBaseFlat" />
    <AdjustableBaseMassage width={30} height={30} mdxType="AdjustableBaseMassage" />
    <AdjustableBaseLight width={30} height={30} mdxType="AdjustableBaseLight" />
    <AdjustableBaseLumbarUp width={30} height={30} mdxType="AdjustableBaseLumbarUp" />
    <AdjustableBaseLumbarDown width={30} height={30} mdxType="AdjustableBaseLumbarDown" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      